<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="close"
  >
    <template slot="title">
      Question Form
    </template>

    <template slot="body">
      <div class="md-layout">
        <div class="md-layout-item">
          <form-html
            v-model="form.question"
            name="question"
            label="Question*"
            :max-chars="1000"
          />

          <form-text
            v-model="form.help_text"
            name="help_text"
            label="Help Text"
            :max-chars="125"
          />

          <LevelsSelect
            v-model="form.level_id"
            label="Level*"
            rules="required"
          />

          <form-select
            v-model="form.category"
            name="category"
            label="Category*"
            rules="required"
            :options="categories"
          />

          <form-radio
            v-model="form.is_active"
            name="is_active"
            label="Status"
            :options="[
              {value:true, label:'Active'},
              {value:false, label:'Inactive'},
            ]"
          />

          <div class="d-flex">
            <ExamQuestionsTextsSelect
              v-model="form.parent_id"
              class="flex-grow-1"
            />
            <md-button
              v-if="form.parent_id"
              class="md-danger md-simple md-just-icon"
              @click="form.parent_id = null"
            >
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>
      </div>

      <div class="md-layout">
        <div class="md-layout-item">
          <md-switch v-model="form.is_text">
            Is Explanation
          </md-switch>
          <small>
            This item can be an explanation or a text to read
            and then answer some questions related to it.
          </small>
        </div>
      </div>
      <div
        v-if="!form.is_text"
        class="md-layout"
      >
        <div class="md-layout-item">
          <div>
            <md-button
              class="md-success md-sm pull-right m-0"
              @click="addAnswer"
            >
              <md-icon class="mr-2">
                add
              </md-icon>
              Add new answer
            </md-button>

            <h4>Answers</h4>
          </div>

          <div v-if="form.answers.length">
            <md-table>
              <md-table-row>
                <md-table-head>Answer</md-table-head>
                <md-table-head>Is Correct?</md-table-head>
                <md-table-head />
              </md-table-row>

              <md-table-row
                v-for="(ans, index) in form.answers"
                :key="ans.key"
              >
                <md-table-cell>
                  <form-text
                    v-model="form.answers[index].text"
                    :name="'answer-'+index"
                    rules="required"
                  />
                </md-table-cell>
                <md-table-cell>
                  <md-switch v-model="ans.is_correct">
                    {{ ans.is_correct ? 'YES' : 'NO' }}
                  </md-switch>
                </md-table-cell>
                <md-table-cell>
                  <md-button
                    class="md-just-icon md-danger"
                    @click="deleteAnswer(index)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <md-button
        class="md-primary pull-right"
        @click="saveForm"
      >
        <md-icon class="mr-2">
          save
        </md-icon>
        Save
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import {
  FormText,
  FormHtml,
  FormSelect,
  FormRadio,
} from '@/components/Inputs';
import { ExamQuestionsTextsSelect, LevelsSelect } from '@/components/Inputs/selects';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

export default {
  components: {
    FormText,
    FormSelect,
    FormRadio,
    FormHtml,
    ExamQuestionsTextsSelect,
    LevelsSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        question: null,
        help_text: null,
        level_id: null,
        category: null,
        is_active: false,
        is_text: false,
        parent_id: null,
        answers: [],
      },
      categories: [
        {
          id: 'Grammar', name: 'Grammar',
        },
        {
          id: 'Vocabulary', name: 'Vocabulary',
        },
      ],
    };
  },
  mounted() {
    if (this.item) this.form = this.item;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    saveForm() {
      const errors = [];
      // Validation
      if (!this.form.question) {
        errors.push('Question attribute is required');
      }
      if (this.form.question.length > 1000) {
        errors.push('Question attribute is too long');
      }
      if (!this.form.level_id) {
        errors.push('Level attribute is required');
      }
      if (!this.form.category) {
        errors.push('Category attribute is required');
      }

      if (!this.form.is_text) {
        if (this.form.answers.length < 2) {
          errors.push('The question must have at least 2 possible answers');
        } else {
          let correct = 0;
          this.form.answers.forEach((x) => {
            if (!x.text) errors.push('The answers cannot be empty');
            if (x.is_correct) correct += 1;
          });

          if (!correct) errors.push('Some answer must be correct');
          if (correct > 1) errors.push('Just one answer can be correct');
        }
      }

      if (!!this.form.help_text && this.form.help_text.length > 200) {
        errors.push('Help text must have less than 200 characters');
      }

      if (errors.length) {
        errors.forEach((x) => {
          this.fireError(x);
        });
      } else if (this.form.placement_question_id) {
        this.request(this.$API.PLACEMENT_QUESTIONS + this.form.placement_question_id, 'put', this.form, () => {
          this.fireSuccess('Question updated succesfully!');
          this.$emit('onSave');
          this.$refs.modal.close();
        });
      } else {
        this.request(this.$API.PLACEMENT_QUESTIONS, 'post', this.form, () => {
          this.fireSuccess('Question created succesfully!');
          this.$emit('onSave');
          this.$refs.modal.close();
        });
      }
    },
    cleanForm() {
      this.form = {
        question: null,
        help_text: null,
        level_id: null,
        category: null,
        is_active: false,
        answers: [],
      };
    },
    addAnswer() {
      const newAnswer = {
        key: Math.random() * Date.now(),
        is_correct: false,
        text: null,
      };
      this.form.answers.push(newAnswer);
    },
    deleteAnswer(index) {
      this.form.answers.splice(index, 1);
    },
  },
};
</script>
