<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newQuestion"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Question</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @onShowDetails="onShowDetails"
              @onDelete="onDelete"
              @changed="getQuestions"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <questions-form
      v-if="showForm"
      :item="selQuestion"
      @close="closeForm"
      @onSave="initTable"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import stringMix from '@/mixins/string';
import QuestionsForm from './QuestionsForm.vue';

export default {
  components: {
    VueTable,
    QuestionsForm,
  },
  mixins: [stringMix],
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'level.name',
            mask: 'level',
            sortable: true,
          },
          {
            title: 'category',
            sortable: true,
          },
          {
            title: 'question',
            sortable: true,
            callback: this.trimHtml,
            max_chars: 100,
          },
          {
            title: 'is_active',
            mask: 'active',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Question',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      showForm: false,
      selQuestion: null,
    };
  },
  mounted() {
    this.initTable();
  },
  methods: {
    getQuestions(params) {
      this.request(this.$API.PLACEMENT_QUESTIONS.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onShowDetails(item) {
      this.selQuestion = { ...item };
      this.selQuestion.answers.map((x) => {
        const aux = x;
        aux.key = Math.random() * Date.now();
        return aux;
      });
      this.showForm = true;
    },
    onDelete(item) {
      this.request(this.$API.PLACEMENT_QUESTIONS + item.placement_question_id, 'delete', null, () => {
        this.initTable();
        this.fireSuccess('Question deleted');
      });
    },
    initTable() {
      this.$refs.vtable.init();
    },
    newQuestion() {
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
      this.selQuestion = null;
    },
  },
};
</script>

<style scoped></style>
